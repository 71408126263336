import { CodeFileType, TreeData } from "../components/tree/CodeTree";

export const reMadeTree = (arr: TreeData[]) => {
  const newArr = arr.map((item) => {
    item.key = item.path;
    item.isOpen = false;
    item.title = item.path.split("/").reverse()[0];
    if (item.type === CodeFileType.tree) {
      item.leaf = false;
      item.children = reMadeTree(item?.children || []);
    } else {
      item.leaf = true;
    }
    return item;
  });
  return newArr;
};
