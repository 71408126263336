import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  CodeDataType,
  useManualServerSentEvents,
} from "../../hook/useManualServerSentEvents";
import Router, { useRouter } from "next/router";
import { ShowMessage } from "../ShowMessage/ShowMessage";
import { accept } from "../UpLoad/UpLoad";
import http from "../../http";
import styles from "../../styles/chat.module.scss";
import { TreeData } from "../tree/CodeTree";
import ChatCreatContent from "./ChatCreatContent";
import ChatTextArea from "./ChatTextArea";
import BottomLayer from "./BottomLayer";
import { useTheme, useUserInfo } from "../../context/ThemeContext";
import Change_theme_icon from "../Icon/light_theme";
import ChatpdfLine from "./ChatpdfLine";
import { Alert } from "antd";
const ChatpdfBox = (
  props: {
    reLoadCancleChat: (id: string) => void;
    cancleChat: (id: string) => Promise<any>;
    getInfo: () => void;
    setLoading: (isLoading: boolean) => void;
    setSettingShow: (is: boolean) => void;
    updateMenu: () => void;
    setData: (
      treeData: TreeData[],
      codeData: CodeDataType,
      isoutputing: boolean
    ) => void;
    gotoLogin: () => void;
    visible: boolean;
    gotoPay: () => void;
  },
  ref: any
) => {
  const markdown = useRef("");
  const file_id = useRef("" as any);
  const thread_id = useRef("" as any);
  const stopEnter = useRef(false);
  const chatTextArea = useRef<any>();
  const chatpdfLine = useRef<any>(null);
  const [fileName, setFileName] = useState("");
  const [chatpdfTips, setChatpdfTips] = useState(true);
  const [upLoading, setUpLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [cancleTag, setCancleTag] = useState(false);
  const [stop, setStop] = useState(false);
  const [chatContent, setChatContent] = useState([] as any);
  const { userInfo } = useUserInfo();
  const { theme, toggleTheme } = useTheme();
  useImperativeHandle(ref, () => {
    return {
      createNewChat,
      getChatLog,
      createThread,
      uploadFile,
    };
  });
  const resetFn = () => {
    setStop(false);
    stopEnter.current = false;
    markdown.current = "";
  };
  const clearFileId = () => {
    file_id.current = null;
    setFileName("");
  };

  const {
    messages,
    startFetching,
    stopFetching,
    treeData,
    codeData,
    responseState,
    isoutputing,
  } = useManualServerSentEvents(
    "/api/v1/chat/chat",
    {
      file_id: file_id.current,
      thread_id: Router.query.thread_id || thread_id.current,
      message: markdown.current,
    },
    clearFileId,
    setStop,
    setFlag,
    props.getInfo,
    resetFn
  );
  useEffect(() => {
    props.setData(treeData, codeData, isoutputing);
  }, [treeData, codeData, isoutputing]);

  // Combine messages and replace '\n\n' with HTML line break '<br /><br />'
  useEffect(() => {
    chatContent[chatContent.length - 1] = {
      text: messages.value,
      user_type: "assistant",
      pending: messages.pending,
    };
    setChatContent([...chatContent]);
  }, [messages]);

  // 聊天
  const createChat = () => {
    //点击发送
    if (upLoading) {
      //上传过程中不能点击发送
      return;
    }
    if (flag) {
      setText("");
      ShowMessage.error(
        "您的今日试用对话机会已用完，请明天继续使用，或者购买会员"
      );
      return;
    }
    if (markdown.current.trim() == "") {
      ShowMessage.error("请输入您需要解读的内容");
      return;
    }
    // 记录当前thread_id
    const ids = JSON.parse(localStorage.getItem("threadIds") || "[]");
    localStorage.setItem(
      "threadIds",
      JSON.stringify([...ids, thread_id.current])
    );
    setChatpdfTips(false);
    setStop(true);
    setChatContent([
      ...chatContent,
      { text: markdown.current, user_type: "user", file_name: fileName },
      { text: "loading", user_type: "assistant" },
    ]);
    startFetching({
      file_id: file_id.current,
      thread_id: thread_id.current,
      message: markdown.current,
    });
    setText("");
    setCancleTag(false);
    setTimeout(() => {
      props.updateMenu();
    }, 500);
    setTimeout(() => {
      if (!chatpdfTips && chatpdfLine.current) {
        chatpdfLine.current?.goToBottom(true);
      }
    }, 200);
  };
  const keyupFun = (e: any) => {
    const { code, shiftKey } = e;
    if (code == "Enter" && !shiftKey && !e.nativeEvent.isComposing) {
      createChat();
    }
  };
  const uploadFile = (files: any[]) => {
    if (upLoading) {
      ShowMessage.error("文件正在上传中，请稍后再试");
      return;
    }
    if (files.length > 1) {
      ShowMessage.error("一次只能上传一个文件");
      return;
    }
    const file = files?.[0];
    // 检查文件后缀是否合法
    if (!file) {
      ShowMessage.error("请选择一个文件");
      return;
    }
    if (file.size > 300 * 1024 * 1024) {
      ShowMessage.error("最大只能上传300MB");
      return;
    }
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    if (!accept.includes(`.${fileExtension}`)) {
      ShowMessage.error(`只能上传${accept.toString()}文件`);
      return;
    }

    setUpLoading(true);
    let formData = new FormData();
    setFileName(file.name);
    formData.append("file", file);
    http
      .post(`/api/v1/chat/file?thread_id=${thread_id.current}`, formData)
      .then((res: any) => {
        if (res.openai_file_id) {
          ShowMessage.success("上传成功");
          file_id.current = res.openai_file_id;
          setFileName(res.original_filename);
        } else {
          ShowMessage.error("上传失败");
          clearFileId();
        }
        setUpLoading(false);
      })
      .catch((err) => {
        ShowMessage.error(err.data.detail || "上传失败");
        clearFileId();
      })
      .finally(() => {
        setUpLoading(false);
      });
  };
  const checkLogin = () => {
    const token = localStorage.getItem("token");
    if (!userInfo.phone_number && !token) {
      props.gotoLogin();
      return false;
    }
    return true;
  };

  const handleInputChange = (e: { target: any }) => {
    const textarea = e.target;
    markdown.current = textarea.value;
  };
  useEffect(() => {
    if (responseState === 500) {
      props.reLoadCancleChat(thread_id.current);
      setCancleTag(true); // 显示已取消
    }
  }, [responseState]);
  // 创建对话
  const createThread = () => {
    // debugger
    setStop(false);
    http
      .post(`/api/v1/chat/thread`)
      .then(
        (data: any) => {
          //debugger
          // this.ControlMessage('加载完成', true);
          if (data.status) {
            if (data.code == 402) {
              setFlag(true);
              props.getInfo();
              return;
            }
            thread_id.current = data.thread_id;
            Router.push(`/?thread_id=${data.thread_id}`).then(() => {
              props.setLoading(false);
            });
            clearFileId();
          } else {
            ShowMessage.error(data.message);
            props.setLoading(false);
          }
        },
        (err) => {
          props.setLoading(false);
        }
      )
      .catch(() => {
        props.setLoading(false);
      });
  };
  // 获取聊天历史
  const getChatLog = async (threadId: any) => {
    Router.push(`/?thread_id=${threadId}`);
    thread_id.current = threadId;
    setText("");
    setCancleTag(false);
    stopFetching();
    resetFn();
    props.setLoading(true);
    props.setSettingShow(false);
    http
      .get(`/api/v1/chat/chat_history?thread_id=${threadId}`)
      .then((data: any) => {
        if (data.status) {
          if (data.data.length == 0) {
            setChatpdfTips(true);
          } else {
            setChatpdfTips(false);
          }
          setChatContent(data.data);
          setTimeout(() => {
            if (!chatpdfTips && chatpdfLine.current) {
              chatpdfLine.current?.goToBottom(true);
            }
          }, 200);
        } else {
          ShowMessage.error(data.message?.output || data.message);
        }
        props.setLoading(false);
      })
      .catch(() => {
        props.setLoading(false);
      });
  };
  const createNewChat = async () => {
    setCancleTag(false);
    setChatpdfTips(true);
    setChatContent([]);
    setText("");
    // setMarkdown('')
    markdown.current = "";
    let res: any = await props.cancleChat(thread_id.current);
    // debugger
    if (!res) {
      return;
    }
    createThread();
  };
  const cancleFn = () => {
    //取消发送
    if (
      chatContent[chatContent.length - 1].text === "loading" ||
      chatContent[chatContent.length - 1].text === ""
    ) {
      //点击取消如果最后一条是内容还是loading或者空字符，说明答案未返回，直接修改成取消
      chatContent[chatContent.length - 1].text = "已取消";
      setChatContent([...chatContent]);
    } else {
      chatContent[chatContent.length - 1].pending = false;
      setChatContent([...chatContent]);
    }
    props.cancleChat(thread_id.current);
    stopFetching();
    resetFn();
    setCancleTag(true);
  };
  const setText = (value: string) => {
    chatTextArea?.current?.setTextAreaValue(value);
  };

  const changeTheme = (newTheme: string) => {
    toggleTheme(newTheme);
    const token = localStorage.getItem("token");
    if (!userInfo.phone_number && !token) {
      return;
    }
    http
      .put("/api/v1/account/edit", {
        theme_color: newTheme === "light" ? 2 : 1,
      })
      .then((res: any) => {
        if (res.status) {
        } else {
          ShowMessage.error(res.message);
        }
      });
  };
  const chatLine = React.useMemo(
    () => (
      <ChatpdfLine
        chatContent={chatContent}
        cancleTag={cancleTag}
        ref={chatpdfLine}
      />
    ),
    [chatContent, cancleTag]
  );
  return (
    <div
      className={styles.chatpdfBox}
      style={props.visible ? {} : { display: "none" }}
    >
      <div className={styles.chatHead}>
        {flag ? (
          <Alert
            showIcon
            message={
              <div>
                您的今日试用对话机会已用完，请明天继续使用，或者
                <a
                  style={{ textDecoration: "underline" }}
                  onClick={props.gotoPay}
                >
                  购买会员
                </a>
              </div>
            }
            type="warning"
          />
        ) : (
          <div />
        )}
        <Change_theme_icon theme={theme} onClick={changeTheme} />
      </div>
      {/* 新建对话模块显示组件 */}
      {chatpdfTips ? (
        <ChatCreatContent
          handleClick={(tip) => {
            if (!checkLogin()) {
              return;
            }
            setChatpdfTips(false);
            setText(tip);
            markdown.current = tip;
          }}
        />
      ) : (
        chatLine
      )}
      {/* 底部对话输入模块 */}
      <div className={styles.chatBottom}>
        <ChatTextArea
          showChatTip={chatpdfTips}
          ref={chatTextArea}
          fileName={fileName}
          onUpload={uploadFile}
          handleInputChange={handleInputChange}
          cancleFn={cancleFn}
          keyupFun={keyupFun}
          createChat={createChat}
          stop={stop}
          upLoading={upLoading}
          onClick={(e) => {
            if (!checkLogin()) {
              e.preventDefault();
            }
          }}
          deleteFile={clearFileId}
        ></ChatTextArea>
        <BottomLayer
          className={styles.tips}
          suffix={"人工智能生成内容仅供参考，使用前请校验内容的正确性。"}
        />
      </div>
    </div>
  );
};

export default forwardRef(ChatpdfBox);
